exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-assembly-tsx": () => import("./../../../src/pages/assembly.tsx" /* webpackChunkName: "component---src-pages-assembly-tsx" */),
  "component---src-pages-care-tsx": () => import("./../../../src/pages/care.tsx" /* webpackChunkName: "component---src-pages-care-tsx" */),
  "component---src-pages-counterstand-tsx": () => import("./../../../src/pages/counterstand.tsx" /* webpackChunkName: "component---src-pages-counterstand-tsx" */),
  "component---src-pages-farmstands-tsx": () => import("./../../../src/pages/farmstands.tsx" /* webpackChunkName: "component---src-pages-farmstands-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-seedlings-tsx": () => import("./../../../src/pages/seedlings.tsx" /* webpackChunkName: "component---src-pages-seedlings-tsx" */),
  "component---src-pages-soil-tsx": () => import("./../../../src/pages/soil.tsx" /* webpackChunkName: "component---src-pages-soil-tsx" */)
}

